import React from 'react'

import SEO from '../components/SEO'
import { AboutLayout } from '../styles/AboutLayout'
import { Headline, Subtitle } from '../styles/Typography'

const AboutPage = () => (
  <>
    <SEO title="About" />
    <AboutLayout>
      <Headline className="text">
        Surely everyone wonders what their grandparents' world would be like in
        real color when they look at their old black and white photos.
      </Headline>
      <Subtitle className="text">
        This is exactly how the idea for <b>colorized.mk</b> was born. Three
        young photography and technology enthusiasts started colorizing old
        photographs for fun and soon after decided to share the photos with the
        world.
      </Subtitle>
      <Subtitle className="text">
        The whole idea is mostly non-profit, but in any case some funds are
        needed to maintain the website and therefore we need your support.{' '}
        <b>Follow, like and share.</b> In the future, there will be a nice
        online store with products from Macedonia.
      </Subtitle>
    </AboutLayout>
  </>
)

export default AboutPage
